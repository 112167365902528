<template>
	<div class="menu">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入标题"/>
					</a-form-item>
					
					<a-form-item label="标识码" name="url" class="ui-form__item">
						<a-input v-model:value="formState.url" placeholder="请输入标识码"/>
					</a-form-item>
					
					<a-form-item label="描述" name="description" class="ui-form__item">
						<a-input v-model:value="formState.description" placeholder="请输入描述"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['system_manage_menu_add']" class="a-primaryBtn" type="primary" @click="onAdd">新建
						</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table :dataSource="dataList" :pagination="pagination" :columns="columns" bordered rowKey="id"
				size="middle">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'operate'">
						<a-dropdown>
							<a @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a>
							<template #overlay>
								<a-menu>
									<div v-permission="['system_manage_menu_edit']" @click="onEdit(record)">
										<a-menu-item>
											<a>编辑</a>
										</a-menu-item>
									</div>
									<div v-permission="['system_manage_menu_delete']" @click="onDelete(record)">
										<a-menu-item>
											<a>删除</a>
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import * as systemApi from '@/service/modules/system';
	import {
		Icon
	} from "@/components/icon/icon.js";
	export default {
		components: {
			Icon
		},
		data() {
			return {
				loading: false,
				formState: {},
				searchData: {},
				dataList: [],
				columns: [{
						title: "标题",
						dataIndex: "title",
						width: "20%",
					},
					{
						title: "标识码",
						dataIndex: "url",
						width: "40%",
					},
					{
						title: "描述",
						dataIndex: "description",
						width: "20%",
					},
					{
						title: "操作",
						dataIndex: "operate",
						width: "20%",
						key: 'operate'
					},
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 20,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			getData() {
				this.loading = true;
				let postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				}
				systemApi.menuList(postData).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.dataList = res.data.list;
						this.pagination.total = res.data.totalCount
					}
				})
			},
			onAdd() {
				this.$router.push({
					path: '/system/systemRoot/menu/add'
				});
			},
			onEdit(item) {
				this.$router.push({
					path: '/system/systemRoot/menu/edit',
					query: {
						id: item.id
					}
				});
			},
			onDelete(item) {
				this.$confirm({
					content: '确定删除吗？',
					onOk: () => {
						this.loading = true;
						systemApi.deleteMenu({
							id: item.id
						}).then(res => {
							this.loading = false;
							if (res.code == 200) {
								this.getData();
							}
						})
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.role {
		// background: red;
		padding: 20px;
	}
	.ui-form__item {
		margin-right: 30px;
	}
	.a-primaryBtn {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin: 20px 0;
	}
</style>
